
<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    view-only
    single-export="Dapat Ditinjau"
  />
</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'view_lkps_rdtm',
      headers: [
        [{
          label: 'Jumlah Dosen Tetap (tabel 4.1.2.2)',
          dataKey: 'jumlah_dosen_tetap_tabel_4_1_2_2',
        }, {
          label: 'Jumlah Mahasiswa Reguler (tabel 3.1.2.2)',
          dataKey: 'jumlah_mahasiswa_reguler_tabel_3_1_2_2',
        }, {
          label: 'Rasio dosen:mahasiswa',
          dataKey: 'rasio_dosen_mahasiswa',
        }]
      ],
      fields: [
        { key: 'jumlah_dosen_tetap_tabel_4_1_2_2', label: 'Jumlah Dosen Tetap (tabel 4.1.2.2)', sortable: true, input: { type: 'input' }},
        { key: 'jumlah_mahasiswa_reguler_tabel_3_1_2_2', label: 'Jumlah Mahasiswa Reguler (tabel 3.1.2.2)', sortable: true, input: { type: 'input' }},
        { key: 'rasio_dosen_mahasiswa', label: 'Rasio dosen:mahasiswa', sortable: true, input: { type: 'input' }},
      ],
    }
  },
}
</script>
